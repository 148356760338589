<template>
  <div
    class="status-label"
    :style="style"
    v-if="currentState || mode == 'editor'"
  >
    <span>
      {{ (currentState && currentState.label) || "TextList" }}
    </span>
  </div>
</template>

<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base.vue";

export default {
  name: "SynopticStatusLabel",
  extends: SynopticEquipmentDataControlBase,
  computed: {
    isDirty() {
      return false;
    },
    currentState: function () {
      var self = this;
      let value =
        typeof this.rawValue == "boolean"
          ? this.rawValue
            ? 1
            : 0
          : this.rawValue;
      var defState = (self.items || []).find(
        (i) => String(i.state).toLowerCase() == "default"
      );
      let state =
        self.items.find(
          (i) => String(i.state) !== "" && String(i.state) === String(value)
        ) ||
        defState ||
        null;
      return state || null;
    },
    items() {
      return this.control?.synopticComponent?.stateImages || [];
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    style() {
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "justify-content": {
          left: "flex-start",
          right: "flex-end",
          center: "center"
        }[ctlStyle["text-align"] || "center"],
        // convert to flex style
        "align-items": {
          top: "flex-start",
          bottom: "flex-end",
          center: "center",
          middle: "center"
        }[ctlStyle["vertical-align"] || "top"],
        "background-color":
          !this.currentState ||
          this.currentState?.backgroundColor == "transparent"
            ? this.controlStyle["background-color"]
            : this.currentState.backgroundColor
      };
    }
  },
  watch: {
    currentState: {
      handler(n) {
        this.$emit("hasContent", n !== null);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.status-label {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.status-label span {
  width: 100%;
}
</style>
